var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("msg.CMBK100_M1.038")) + " ")]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.ONIM010P020.001"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content_box mt10" },
          [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONIM010P020.002"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.003")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.deadlineInfoDetail.mrnNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.004")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.deadlineInfoDetail.msnNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.005")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.deadlineInfoDetail.anSendDt))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.006")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.deadlineInfoDetail.mfSendDt))]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONIM010P020.009"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_col tal" }, [
              _vm._m(1),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.008")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.009")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.010")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.011")))]),
                ]),
              ]),
              _c("tbody", [
                !_vm.podPortManager.usrNm
                  ? _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.012")))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.importManagerDetail.name))]),
                      _c("td", [
                        _vm._v(_vm._s(_vm.importManagerDetail.telNumber)),
                      ]),
                      _c("td", [_vm._v(_vm._s(_vm.importManagerDetail.email))]),
                    ])
                  : _vm._e(),
                _vm.podPortManager.usrNm
                  ? _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.012")))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.podPortManager.usrNm))]),
                      _c("td", [_vm._v(_vm._s(_vm.podPortManager.usrPhone))]),
                      _c("td", [_vm._v(_vm._s(_vm.podPortManager.usrEmail))]),
                    ])
                  : _vm._e(),
                _vm.podCtrManager.usrNm
                  ? _c("tr", [
                      _c("td", [_vm._v(_vm._s(_vm.$t("msg.ONIM010P020.014")))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.podCtrManager.usrNm))]),
                      _c("td", [_vm._v(_vm._s(_vm.podCtrManager.usrPhone))]),
                      _c("td", [_vm._v(_vm._s(_vm.podCtrManager.usrEmail))]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm.parentInfo.podCtrCd === "JP" &&
            (_vm.trackingInfoJPList.length > 0 ||
              _vm.trackingInfoJPListFull.length > 0)
              ? [
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v("POD INFORMATION( A/N 発行場所 / CY )"),
                  ]),
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(2),
                    _vm.trackingInfoJPListFull.length > 0
                      ? _c(
                          "tbody",
                          [
                            _vm._l(
                              _vm.trackingInfoJPListFull,
                              function (item, idx) {
                                return _c("tr", { key: idx }, [
                                  _c("th", [
                                    _vm._v(_vm._s(item ? item.closInfNm : "")),
                                  ]),
                                  _c("td", { attrs: { colspan: "3" } }, [
                                    _c("pre", { staticClass: "font13" }, [
                                      _vm._v(
                                        _vm._s(item ? item.closInfVar : "")
                                      ),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trackingInfoJPList[0]
                                      ? _vm.trackingInfoJPList[0].closInfNm
                                      : ""
                                  )
                                ),
                              ]),
                              _c("td", [
                                _c("pre", { staticClass: "font13" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trackingInfoJPList[0]
                                        ? _vm.trackingInfoJPList[0].closInfVar
                                        : ""
                                    )
                                  ),
                                ]),
                              ]),
                              _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trackingInfoJPList[1]
                                      ? _vm.trackingInfoJPList[1].closInfNm
                                      : ""
                                  )
                                ),
                              ]),
                              _c("td", [
                                _c("pre", { staticClass: "font13" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.trackingInfoJPList[1]
                                        ? _vm.trackingInfoJPList[1].closInfVar
                                        : ""
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                          ],
                          2
                        )
                      : _vm.trackingInfoJPListFull.length === 0 &&
                        _vm.trackingInfoJPList.length > 0
                      ? _c("tbody", [
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.trackingInfoJPList[0]
                                    ? _vm.trackingInfoJPList[0].closInfNm
                                    : ""
                                )
                              ),
                            ]),
                            _c("td", [
                              _c("pre", { staticClass: "font13" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trackingInfoJPList[0]
                                      ? _vm.trackingInfoJPList[0].closInfVar
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.trackingInfoJPList[1]
                                    ? _vm.trackingInfoJPList[1].closInfNm
                                    : ""
                                )
                              ),
                            ]),
                            _c("td", [
                              _c("pre", { staticClass: "font13" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trackingInfoJPList[1]
                                      ? _vm.trackingInfoJPList[1].closInfVar
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [
                              _vm._v(
                                _vm._s(
                                  _vm.trackingInfoJPList[2]
                                    ? _vm.trackingInfoJPList[2].closInfNm
                                    : ""
                                )
                              ),
                            ]),
                            _c("td", { attrs: { colspan: "3" } }, [
                              _c("pre", { staticClass: "font13" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.trackingInfoJPList[2]
                                      ? _vm.trackingInfoJPList[2].closInfVar
                                      : ""
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ])
                      : _c("tbody", [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSCT060G030.022"))),
                            ]),
                          ]),
                        ]),
                  ]),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "25%" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "24%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
      _c("col", { staticStyle: { width: "26%" } }),
      _c("col", { staticStyle: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "30%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }