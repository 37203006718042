<template>
  <div
    class="popup_wrap"
    style="width:900px; height:600px;"
  >
    <!-- popup_wrap 팝업사이즈 width:800px; height:540px; -->
    <button
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONIM010P020.001') }}</h1> <!-- 수입업무 담당자 정보 -->
      <div class="content_box mt10">
        <!-- content_box -->
        <h2 class="content_title">{{ $t('msg.ONIM010P020.002') }}</h2> <!-- 마감시간 -->
        <table class="tbl_row">
          <colgroup>
            <col width="25%"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONIM010P020.003') }}</th> <!-- MRN No. -->
              <td>{{ deadlineInfoDetail.mrnNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONIM010P020.004') }}</th> <!-- MSN -->
              <td>{{ deadlineInfoDetail.msnNo }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONIM010P020.005') }}</th> <!-- 적하목록마감 -->
              <td>{{ deadlineInfoDetail.anSendDt }}</td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONIM010P020.006') }}</th> <!-- 입항전신고마감 -->
              <td>{{ deadlineInfoDetail.mfSendDt }}</td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.ONIM010P020.009') }}</h2> <!-- 담당자 -->
        <table class="tbl_col tal">
          <colgroup>
            <col style="width:24%">
            <col style="width:25%">
            <col style="width:26%">
            <col style="width:25%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.ONIM010P020.008') }}</th> <!-- 구분 -->
              <th>{{ $t('msg.ONIM010P020.009') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.ONIM010P020.010') }}</th> <!-- TEL -->
              <th>{{ $t('msg.ONIM010P020.011') }}</th> <!-- email -->
            </tr>
          </thead>
          <tbody>
            <tr v-if="!podPortManager.usrNm">
              <!-- KPO, DSN, KAN이 아닌(commonUtils.plcFnc에 정보가 없는) 경우 조회된 내역을 출력 -->
              <td>{{ $t('msg.ONIM010P020.012') }}</td> <!-- 수입 신고 -->
              <td>{{ importManagerDetail.name }}</td>
              <td>{{ importManagerDetail.telNumber }}</td>
              <td>{{ importManagerDetail.email }}</td>
            </tr>
            <!-- 수입 업무 : 다큐센터 수입팁 삭제 2022.05.27-->
            <!-- <tr v-if="parentInfo.podPortCd === 'KAN' || parentInfo.podPortCd === 'DSN' || parentInfo.podPortCd === 'KPO'">
              <td>{{ $t('msg.ONIM010P020.013') }}</td>
              <td>{{ importNoneManagerDetail.name }}</td>
              <td>{{ importNoneManagerDetail.telNumber }}</td>
              <td>{{ importNoneManagerDetail.email }}</td>
            </tr> -->
            <!-- 수입 업무 -->
            <tr v-if="podPortManager.usrNm">
              <!-- KPO, DSN, KAN향(commonUtils.plcFnc에 정보가 있는) 경우 고정값 호출 -->
              <!-- <td>{{ $t('msg.ONIM010P020.013') }}</td> -->
              <td>{{ $t('msg.ONIM010P020.012') }}</td> <!-- 수입 신고 -->
              <td>{{ podPortManager.usrNm }}</td>
              <td>{{ podPortManager.usrPhone }}</td>
              <td>{{ podPortManager.usrEmail }}</td>
            </tr>

            <tr v-if="podCtrManager.usrNm">
              <td>{{ $t('msg.ONIM010P020.014') }}</td> <!-- 수입 발급(D/O) -->
              <td>{{ podCtrManager.usrNm }}</td>
              <td>{{ podCtrManager.usrPhone }}</td>
              <td>{{ podCtrManager.usrEmail }}</td>
            </tr>
          </tbody>
        </table>

        <template v-if="parentInfo.podCtrCd === 'JP' && (trackingInfoJPList.length > 0 || trackingInfoJPListFull.length > 0)">
          <h2 class="content_title">POD INFORMATION( A/N 発行場所 / CY )</h2>
          <table class="tbl_row">
            <colgroup>
              <col style="width:20%">
              <col style="width:30%">
              <col style="width:20%">
              <col style="width:30%">
            </colgroup>
            <tbody v-if="trackingInfoJPListFull.length > 0">
              <tr v-for="(item, idx) in trackingInfoJPListFull" :key="idx">
                <th>{{ item? item.closInfNm : '' }}</th>
                <td colspan="3"><pre class="font13">{{ item? item.closInfVar : '' }}</pre></td>
              </tr>
              <tr>
                <th>{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfVar : '' }}</pre></td>

                <th>{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfVar : '' }}</pre></td>
              </tr>
            </tbody>
            <tbody v-else-if="trackingInfoJPListFull.length === 0 && trackingInfoJPList.length > 0">
              <tr>
                <th>{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[0]? trackingInfoJPList[0].closInfVar : '' }}</pre></td>

                <th>{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfNm : '' }}</th>
                <td><pre class="font13">{{ trackingInfoJPList[1]? trackingInfoJPList[1].closInfVar : '' }}</pre></td>
              </tr>
              <tr>
                <th>{{ trackingInfoJPList[2]? trackingInfoJPList[2].closInfNm : '' }}</th>
                <td colspan="3"><pre class="font13">{{ trackingInfoJPList[2]? trackingInfoJPList[2].closInfVar : '' }}</pre></td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="2">{{ $t('msg.CSCT060G030.022') }}</td><!-- No Data. -->
              </tr>
            </tbody>
          </table>
        </template>
      </div><!-- content_box // -->
    </div>
  </div>
</template>

<script>
import arrivalNotice from '@/api/rest/trans/arrivalNotice'
import commonUtils from '@/plugins/commonUtils'
import importSvc from '@/api/rest/trans/importSvc'

export default {
  name: 'ImportManagePop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          podPortCd: ''
        }
      }
     }
  },
  data () {
    return {
      blNo: '',
      doStsCd: '',
      deadlineInfoDetail: {},
      importManagerDetail: {},
      importNoneManagerDetail: {},
      podPortManager: {},
      podCtrManager: {},
      trackingInfoJPList: [],
      trackingInfoJPListFull: []
    }
  },
  created () {
    console.log('created')
  },
  mounted () {
    console.log('mounted')
    this.blNo = this.parentInfo.blNo
    const rtnObj = commonUtils.plcFnc(this.parentInfo.podPortCd, '')
    if (rtnObj) {
      this.podPortManager = rtnObj
    }

    const rtnObj_ = commonUtils.dlyFnc(this.parentInfo.podCtrCd, '')
    if (rtnObj_) {
      this.podCtrManager = rtnObj_
    }
    this.getDeadlineInfo()

    const THM = this
    const vslCd = this.parentInfo.vslCd
    const podPortCd = this.parentInfo.podPortCd
    const podTrmlCd = this.parentInfo.podTrmlCd

    const params = {
          podPortCd,
          podTrmlCd,
          vslCd
      }

    // JP 일때, WEB INFO TRACKING 정보 검색
    importSvc.getTrackingInfo(params)
      .then(res => {
        const trackingInfoJPList = res.data

        for (var item of trackingInfoJPList) {
          if (item.podPortCd === podPortCd) {
            if (item.rowCd === '01') {
              THM.trackingInfoJPListFull.push(item)
            } else {
              THM.trackingInfoJPList.push(item)
            }

            THM.trackingInfoJPList = this.$_.sortBy(THM.trackingInfoJPList, ['seq'])
          }
        }
      })
  },
  methods: {
    async getDeadlineInfo () {
      const result = await arrivalNotice.findImportManagerDeadlineInfo((this.blNo))
      console.log(result)
      this.deadlineInfoDetail = { ...result.data.deadlineInfoDetail }
      this.importManagerDetail = { ...result.data.importManager }
      this.importNoneManagerDetail = { ...result.data.importNoneManager }

      // console.log('importNoneManagerDetail :::: ', this.importNoneManagerDetail)

      if (this.deadlineInfoDetail.anSendDt) {
        this.deadlineInfoDetail.anSendDt = this.getFormatDateTM(this.deadlineInfoDetail.anSendDt)
      }
      if (this.deadlineInfoDetail.mfSendDt) {
        this.deadlineInfoDetail.mfSendDt = this.getFormatDateTM(this.deadlineInfoDetail.mfSendDt)
      }

      /*
       * <DO_STS_CD 상태 정의>
       * -참고 : AS-IS D/O 발행 메뉴
       *
       * 1 : D/O 발행(담당자 확인 단계)
       * 2 : D/O 발행(담당자 확인 단계)
       * 3 : 발행요청접수
       * 4 : 미발행
       * 5 : B/L 미접수
       * 6 : 발행거절(담당자 확인 단계)
       * 7 : 발행가능(담당자 확인 단계)
       * 8 : 발행취소(담당자 확인 단계)
       * 9 : Pending(담당자 확인 단계)
       */
      if (this.deadlineInfoDetail.doStsCd) {
        this.doStsCd = Number(result.data.deadlineInfoDetail.doStsCd)
      }
    },
    getFormatDateTM (value) {
      let strYear
      let strMonth
      let strDay
      let strDt
      let strTm

      if (value.length === 12) {
        strYear = (value).substr(0, 4) + '.'
        strMonth = (value).substr(4, 2) + '.'
        strDay = (value).substr(6, 2) + ' '
        strDt = (value).substr(8, 2) + ':'
        strTm = (value).substr(10, 2)
        return strYear + strMonth + strDay + strDt + strTm
      } else if (value.length === 8) {
        strYear = (value).substr(0, 4) + '.'
        strMonth = (value).substr(4, 2) + '.'
        strDay = (value).substr(6, 2)
        return strYear + strMonth + strDay
      } else {
        return value
      }
    }
  }
}
</script>

<style scoped>
font13 {
  font-size:13px;
}
</style>
